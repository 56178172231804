import projImg1 from './assets/img/in-jobs.jpg';
import projImg2 from './assets/img/furnon.jpg';
import projImg3 from './assets/img/wysiwyg.jpg';
import projImg4 from './assets/img/ourmark.jpg';
import projImg5 from './assets/img/e-school.jpg';
import projImg6 from './assets/img/techquiz.jpg';
import projImg7 from './assets/img/pmastery.png';
import projImg8 from './assets/img/moneyTracker.png';

const projects = [
  {
    title: 'P-Mastery',
    description:
      'Application for managing PFE (Final Year Projects) and Defenses/Presentations',
    imgUrl: projImg7,
    linkGitRepo: 'private',
  },
  {
    title: 'in-jobs',
    description: 'Web scrapping tool for Linkedin jobs',
    imgUrl: projImg1,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/injobs-scraper',
  },

  {
    title: 'FURNON',
    description: 'Online supplies selling platform',
    imgUrl: projImg2,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/FURNON-MERN',
  },
  {
    title: 'WYSIWYG Editor',
    description: '"What You See Is What You Get" Text Editor',
    imgUrl: projImg3,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/WYSIWYG-Editor',
  },

  {
    title: 'OurMark',
    description: 'IT company platform',
    imgUrl: projImg4,
    linkGitRepo:
      'https://github.com/MOUAD-FAKIHI/OurMark-Web-Development-Project',
  },
  {
    title: 'TechQuiz',
    description: 'Mobile application of quizzes on programming technologies',
    imgUrl: projImg6,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/TechQuiz',
  },

  {
    title: 'E-School',
    description: 'School management desktop application',
    imgUrl: projImg5,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/Eschool-Desktop-Application',
  },
  {
    title: 'M-Tracker',
    description: 'Money Tracker mobile application',
    imgUrl: projImg8,
    linkGitRepo: 'https://github.com/MOUAD-FAKIHI/money-tracker-app',
  },
];

export default projects;
