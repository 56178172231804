import { Navbar, Container, Nav } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import logo from '../assets/img/mf01White.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, seScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="lg" className={scrolled ? 'scrolled' : ''}>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#home"
              className={
                activeLink === 'home'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={
                activeLink === 'skills'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('skills')}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={
                activeLink === 'projects'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('projects')}
            >
              Projects
            </Nav.Link>
            <Nav.Link
              href="#formation"
              className={
                activeLink === 'education'
                  ? 'active navbar-link text-center'
                  : 'navbar-link text-center'
              }
              onClick={() => onUpdateActiveLink('formation')}
            >
              Formation
            </Nav.Link>
          </Nav>
          <span className="navbar-text flex-column flex-lg-row">
            <div className="social-icon mt-2 mb-3 mt-lg-0 mb-lg-0">
              <a
                href="https://www.linkedin.com/in/mouad-fakihi"
                className="me-2"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i>
                  <FontAwesomeIcon icon={faLinkedin} />
                </i>
              </a>
              <a
                href="https://github.com/MOUAD-FAKIHI"
                target="_blank"
                rel="noreferrer noopener"
              >
                                <i>
                  <FontAwesomeIcon icon={faGithub} />
                </i>
              </a>
              <a href="mailto:mouadfakihi01@gmail.com" className="ms-2">
                <i>
                  <FontAwesomeIcon icon={faAt} />
                </i>
              </a>
            </div>
            <a href="#connect">
              <button className="ms-lg-4">
                <span>Let's Connect</span>
              </button>
            </a>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
