import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/img/mf01White.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container className='p-5'>
        <Row className="align-items-center">
          <Col size={12} sm={12} md={6} className="d-flex justify-content-center justify-content-md-start">
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={12} md={6} className="text-center text-center text-md-end">
            <div className="social-icon pt-4">
            <a
                href="https://www.linkedin.com/in/mouad-fakihi"
                className="me-2"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i>
                  <FontAwesomeIcon icon={faLinkedin} />
                </i>
              </a>
              <a
                href="https://github.com/MOUAD-FAKIHI"
                target="_blank"
                rel="noreferrer noopener"
              >
                                <i>
                  <FontAwesomeIcon icon={faGithub} />
                </i>
              </a>
              <a href="mailto:mouadfakihi01@gmail.com" className="ms-2">
                <i>
                  <FontAwesomeIcon icon={faAt} />
                </i>
              </a>
            </div>
            <p>Copyright © 2023 MOFA</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
