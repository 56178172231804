import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Formation = () => {
  return (
    <div className="formation p-5" id="formation">
      <h2>Formation</h2>
      <VerticalTimeline lineColor="#3557a2">
        <VerticalTimelineElement
          className="vertical-timeline-element--formation"
          date="2017 - 2018"
          iconStyle={{ background: '#3557a2', color: '#fff' }}
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
        >
          <h3 className="vertical-timeline-element-title">
            Bachelor of science physics
          </h3>
          <p> Lalla Salma High School, Rissani</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--formation"
          date="2018 - 2019"
          iconStyle={{ background: '#3557a2', color: '#fff' }}
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
        >
          <h3 className="vertical-timeline-element-title">
            TC Mathematics Computer Science Physics
          </h3>
          <p>Faculty of Science and Technology, Errachidia</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--formation"
          date="2019 - 2021"
          iconStyle={{ background: '#3557a2', color: '#fff' }}
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
        >
          <h3 className="vertical-timeline-element-title">
            Preparatory cycle - Sciences and Techniques for engineering studies
          </h3>
          <p>National School of Applied Sciences, Oujda</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--formation"
          date="2021 - 2024"
          iconStyle={{ background: '#3557a2', color: '#fff' }}
          icon={<FontAwesomeIcon icon={faGraduationCap} />}
        >
          <h3 className="vertical-timeline-element-title">
            Engineering Cycle in Computer Science
          </h3>
          <p>National School of Applied Sciences, Oujda</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};
