import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import headerImg from '../assets/img/portfolio_photo.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';

export const Banner = () => {
  const [loopNum, setloopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 2000;

  useEffect(() => {
    const toRotate = ['Full Stack Developer', 'Passionate Coder', 'Designer'];
    const tick = () => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1);

      setText(updatedText);

      if (isDeleting) {
        setDelta((prevDelta) => prevDelta / 2);
      }

      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setloopNum(loopNum + 1);
        setDelta(500);
      }
    };

    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text, delta, loopNum, isDeleting]);

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7} className='mb-5 mb-md-0'>
            <span className="tagline">Welcome to my Portfolio</span>
            <h1>
              Hi! I'm <span className="myFullName">Mouad FAKIHI</span>
            </h1>
            <h1>
              <span
                className="txt-rotate"
                dataPeriod="1000"
                data-rotate="['Full Stack Developer', 'Passionate Coder', 'Designer']"
              >
                <span className="wrap">{text}</span>
              </span>
            </h1>
            <p>
              Hello there! I am a skilled developer in building websites and web
              applications for small to medium sized businesses. Whether you
              need a simple portfolio site, a custom e-commerce store, or a
              complex web application, I can help you bring your vision to life.
            </p>
            <a className="text-decoration-none" href="#connect">
              <button>
                Let's Connect <ArrowRightCircle size={25} />
              </button>
            </a>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Headder Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
