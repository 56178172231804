import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

export const ProjectCard = ({ title, description, imgUrl, linkGitRepo }) => {
  return (
    <Col sm={12} lg={6}>
      {linkGitRepo !== 'private' ? (
        <a href={linkGitRepo} target="_blank" rel="noreferrer noopener">
          <div className="proj-imgbx">
            <img alt="project_image" src={imgUrl} />
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </a>
      ) : (
        <span
          className="project-pointer"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() =>
            toast.dark(
              'This project is private contact me for more informations'
            )
          }
        >
          <div className="proj-imgbx">
            <img alt="project_image" src={imgUrl} />
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </span>
      )}
    </Col>
  );
};
