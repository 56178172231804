import { Container, Col, Row } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import dataProjects from '../dataProjects';
import { Carousel } from 'react-bootstrap';

export const Projects = () => {
  const projects = dataProjects.reduce((acc, curr, index) => {
    if (index % 2 === 0) {
      acc.push(dataProjects.slice(index, index + 2));
    }
    return acc;
  }, []);

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <div className="mt-5">
              <Carousel variant="dark" fade>
                {projects.map((twoProjects, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <Row>
                        {twoProjects.map((project, index) => {
                          return <ProjectCard key={index} {...project} />;
                        })}
                      </Row>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
