import { Container, Row, Col } from 'react-bootstrap';
import { Divider } from 'primereact/divider';

export const Skills = () => {
  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills</h2>
              <Row>
                <Divider className="dividerText" align="center">
                  Programming languages
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align-items-cent justify-content-center">
                  <i className="devicon-javascript-plain"></i>
                  <i className="devicon-typescript-plain"></i>
                  <i className="devicon-java-plain"></i>
                  <i className="devicon-php-plain"></i>
                  <i class="devicon-c-plain"></i>
                  <i class="devicon-cplusplus-plain"></i>
                  <i class="devicon-csharp-plain"></i>
                </div>
              </Row>
              <Row>
                <Divider className="dividerText" align="center">
                  Frameworks
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align-content-between align-items-cent justify-content-center">
                  <i className="devicon-react-original"></i>
                  <i className="devicon-nodejs-plain"></i>
                  <i className="devicon-bootstrap-plain"></i>
                  <i className="devicon-angularjs-plain"></i>
                  <i className="devicon-express-original"></i>
                  <i className="devicon-spring-plain"></i>
                </div>
              </Row>
              <Row>
                <Divider className="dividerText" align="center">
                  Markup languages
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align-content-between align-items-cent justify-content-center">
                  <i className="devicon-html5-plain"></i>
                  <i className="devicon-css3-plain"></i>
                </div>
              </Row>
              <Row>
                <Divider className="dividerText" align="center">
                  Database
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align-content-between align-items-cent justify-content-center">
                  <i className="devicon-mysql-plain"></i>
                  <i className="devicon-mongodb-plain"></i>
                  <i className="devicon-firebase-plain"></i>
                  <i className="devicon-sqlite-plain"></i>
                </div>
              </Row>
              <Row>
                <Divider className="dividerText" align="center">
                  Tools
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align align-content-between-items-cent justify-content-center">
                  <i className="devicon-github-original"></i>
                  <i className="devicon-jira-plain"></i>
                  <i className="devicon-vscode-plain"></i>
                  <i class="devicon-visualstudio-plain"></i>
                  <i className="devicon-intellij-plain"></i>
                  <i className="devicon-androidstudio-plain"></i>
                </div>
              </Row>
              <Row>
                <Divider className="dividerText" align="center">
                  Design
                </Divider>
                <div className="mt-2 mb-2 d-flex flex-wrap align-content-between align-items-cent justify-content-center">
                  <i className="devicon-illustrator-plain"></i>
                  <i className="devicon-figma-plain"></i>
                  <i className="devicon-canva-original"></i>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
