import { useRef, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import contactImg from '../assets/img/Mention-bro.svg';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  // const [buttonText, setButtonText] = useState('send');
  // const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('submit');
  // };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'mofaPortfolio',
        'template_9t80hzc',
        form.current,
        'X3B1kolNQ3CD-NE9N'
      )
      .then(
        (result) => {
          setFormDetails(formInitialDetails);
          if (result) toast.dark('Message sent successfully');
          // console.log(result.text);
        },
        (error) => {
          if (error) toast.dark('Message failed to send');
          // console.log(error.text);
        }
      );
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={contactImg} alt="Contact us" />
          </Col>
          <Col md={6}>
            <h2>Get In Touch</h2>
            <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col sm={6} className="px-1">
                  <input
                    required
                    value={formDetails.firstName}
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    onChange={(e) => onFormUpdate('firstName', e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    required
                    value={formDetails.lastName}
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={(e) => onFormUpdate('lastName', e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    required
                    value={formDetails.email}
                    type="email"
                    name="user_email"
                    placeholder="Email Address"
                    onChange={(e) => onFormUpdate('email', e.target.value)}
                  />
                </Col>
                <Col sm={6} className="px-1">
                  <input
                    required
                    value={formDetails.phone}
                    type="tel"
                    name="user_phone"
                    placeholder="Phone No."
                    onChange={(e) => onFormUpdate('phone', e.target.value)}
                  />
                </Col>
                <Col className="px-1">
                  <textarea
                    required
                    value={formDetails.message}
                    row="6"
                    name="message"
                    placeholder="Message"
                    onChange={(e) => onFormUpdate('message', e.target.value)}
                  ></textarea>
                  <button type="submit">
                    <span>Send</span>
                  </button>
                </Col>
                {/* {status.message && (
                  <Col>
                    <p
                      className={status.succes === false ? 'danger' : 'success'}
                    >
                      {status.message}
                    </p>
                  </Col>
                )} */}
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
